import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Text from "../components/sections/Text"

const Teachers = ({ data }) => {
  const { markdownRemark } = data
  const {
    frontmatter: { title },
    html,
  } = markdownRemark

  return (
    <Layout>
      <SEO title={title} />
      <Text title={title} html={html} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageQuery($locale: String) {
    markdownRemark(
      frontmatter: { lang: { eq: $locale }, slug: { eq: "/teachers" } }
    ) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`

export default Teachers
